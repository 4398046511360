'use strict';

window.friendlyPix = window.friendlyPix || {};

/**
 * Handles the single post UI.
 */
friendlyPix.Post = class
{
  /**
   * Initializes the single post's UI.
   * @constructor
   */
  constructor()
  {
    // List of all times running on the page.
    this.timers = [];

    // Firebase SDK.
    this.database = firebase.database();
    this.storage = firebase.storage();
    this.auth = firebase.auth();

    $(document).ready(() =>
    {
      this.postPage = $('#page-post');
      // Pointers to DOM elements.
      this.postElement = $(friendlyPix.Post.createPostHtml());
      friendlyPix.MaterialUtils.upgradeTextFields(this.postElement);

      // Post Freebie Element//////////////////////////////////////////////////////////////////////////////////
      this.freebieElement = $(friendlyPix.Post.createFreebieFeedHtml());
      friendlyPix.MaterialUtils.upgradeTextFields(this.freebieElement);

      this.toast = $('.mdl-js-snackbar');
      this.theatre = $('.fp-theatre');
    });
  }

  /**
   * Loads the given post's details.
   */
  loadPost(postId)
  {
    // Load the posts information.
    friendlyPix.firebase.getPostData(postId).then(snapshot =>
    {
      const post = snapshot.val();
      // Clear listeners and previous post data.
      this.clear();
      if (!post)
       {
        var data = {
          message: 'This post does not exists.',
          timeout: 5000
        };
        this.toast[0].MaterialSnackbar.showSnackbar(data);
        if (this.auth.currentUser)
        {
          page(`/user/${this.auth.currentUser.uid}`);
        } else {
          page(`/freebie`);
        }
      } else
      {

        this.fillPostData(snapshot.key, post.thumb_url || post.url, post.text, post.body_text, post.author,
            post.timestamp, post.thumb_storage_uri, post.full_storage_uri, post.full_url, post.freebie_url,
            post.startDate,post.endDate, post.saving, post.organizer, post.online, post.offline,
             post.feedback, post.daysToClaim, post.forum_url, post.article_type);
      }
    });
  }

  /**
   * Clears all listeners and timers in the given element.
   */
  clear()
  {
    // Stops all timers if any.
    this.timers.forEach(timer => clearInterval(timer));
    this.timers = [];

    // Remove Firebase listeners.
    friendlyPix.firebase.cancelAllSubscriptions();
  }

  /**
   * Displays the given list of `comments` in the post.
   */
  displayComments(comments)
{
    const commentsIds = Object.keys(comments);
    for (let i = commentsIds.length - 1; i >= 0; i--)
    {
      $('.fp-comments', this.postElement).prepend(
          friendlyPix.Post.createCommentHtml(comments[commentsIds[i]].author,
              comments[commentsIds[i]].text));
    }
  }

  /**
   * Shows the "show more comments" button and binds it the `nextPage` callback. If `nextPage` is
   * `null` then the button is hidden.
   */
  displayNextPageButton(nextPage)
  {
    const nextPageButton = $('.fp-morecomments', this.postElement);
    if (nextPage) {
      nextPageButton.show();
      nextPageButton.unbind('click');
      nextPageButton.prop('disabled', false);
      nextPageButton.click(() => nextPage().then(data => {
        nextPageButton.prop('disabled', true);
        this.displayComments(data.entries);
        this.displayNextPageButton(data.nextPage);
      }));
    } else {
      nextPageButton.hide();
    }
  }

  /**
   * Fills the post's Card with the given details.
   * Also sets all auto updates and listeners on the UI elements of the post.
   */
  fillPostData(postId, thumbUrl, imageText,body_text, author, timestamp, thumbStorageUri, picStorageUri, picUrl,
                      campaignUrl, startDate, endDate, saving, organizer, online, offline, feedback, daysToClaim, forumUrl)
  {
    const post = this.postElement;

    // Fills element's author profile.
    $('.fp-usernamelink', post).attr('href', `/user/${author.uid}`);
    $('.fp-avatar', post).css('background-image',
        `url(${author.profile_picture || '/images/silhouette.jpg'})`);
    $('.fp-username', post).text(author.full_name || 'Anonymous');

    // Shows the pic's thumbnail.
    this._setupThumb(thumbUrl, picUrl, post);
    this._setupThumbView(thumbUrl, picUrl, post);

    /*/ Make sure we update if the thumb or pic URL changes.
    friendlyPix.firebase.registerForThumbChanges(postId, thumbUrl => {
      this._setupThumb(thumbUrl, picUrl, post);
    });*/

    this._setupDate(postId, timestamp, post);
    this._setupDeleteButton(postId, author, picStorageUri, thumbStorageUri, post);
    this._setupLikeCountAndStatus(postId, post);
    this._setupComments(postId, author, imageText, post);
    this._setupTitle(imageText, post);
    this._setupBodyText(body_text, post);
    this._setupCampaignUrl(campaignUrl, post);
    this._setupFreebieDates(startDate, endDate, post);
    this._setupSaving(saving, post);
    //this._setupOrganizer(organizer, post);
    this._setupForumUrl(forumUrl, post);
    this._setupFreebieCharacteristics(online, offline, feedback, daysToClaim, post);
    return post;
  }

    /**
     * Fills the post's Card with the given details.
     * Also sets all auto updates and listeners on the UI elements of the post.
     */
    fillFreebieData(postId, thumbUrl, imageText,body_text, author, timestamp, thumbStorageUri, picStorageUri, picUrl, campaignUrl)
    {
      const post = this.freebieElement;

      // Fills element's author profile.
      $('.fp-usernamelink', post).attr('href', `/user/${author.uid}`);
      $('.fp-avatar', post).css('background-image',
          `url(${author.profile_picture || '/images/silhouette.jpg'})`);
      $('.fp-username', post).text(author.full_name || 'Anonymous');

      // Shows the pic's thumbnail.
      this._setupThumb(thumbUrl, picUrl, post);

      /*/ Make sure we update if the thumb or pic URL changes.
      friendlyPix.firebase.registerForThumbChanges(postId, thumbUrl => {
        this._setupThumb(thumbUrl, picUrl, post);
      });*/

      this._setupDate(postId, timestamp, post);
      this._setupLikeCountAndStatus(postId, post);
      this._setupMyFreebieStatus(postId,post);
      this._setupTitle(imageText, post);
      this._setupFreebieLinks(post, postId);
      this._setupBodyText(body_text, post);
      return post;
    }


     /**
     * Setup Links to the full Freebie Post
     * @private
     */
    _setupFreebieLinks(post, postId){
      $('.fp-title-link', post).attr('href', `/post/${postId}`);
    }

     /**
     * Shows the publishing title of the post.
     * @private
     */
      _setupTitle(imageText, post)
      {
        $('.mdl-card__title-text', post).text(imageText);
      }

      /**
      * Shows the publishing body Text of the post.
      * @private
      */
     _setupBodyText(bodyText, post)
     {
       $('.fp-freebie_body_text', post).text(bodyText);
     }



  /**
   * Leaves the theatre mode.
   */
  leaveTheatreMode() {
    this.theatre.hide();
    this.theatre.off('click');
    $(document).off('keydown');
  }

  /**
   * Leaves the theatre mode.
   */
  enterTheatreMode(picUrl) {
    $('.fp-fullpic', this.theatre).prop('src', picUrl);
    this.theatre.css('display', 'flex');
    // Leave theatre mode if click or ESC key down.
    this.theatre.off('click');
    this.theatre.click(() => this.leaveTheatreMode())
    $(document).off('keydown');
    $(document).keydown(e => {
      if (e.which === 27) {
        this.leaveTheatreMode();
      }
    });
  }


  /**
   * Shows the thumbnail and sets up the click to see the full size image.
   * @private
   */
  _setupThumb(thumbUrl, picUrl, post)
  {
    $('.fp-image', post).css('background-image', `url("${thumbUrl ? thumbUrl.replace(/"/g, '\\"') : ''}")`);
  }

    /**
     * Sets up the click to see the full size image.
     * @private
     */
    _setupThumbView(thumbUrl, picUrl, post)
    {
      $('.fp-image', post).unbind('click');
      $('.fp-image', post).click(() => this.enterTheatreMode(picUrl || thumbUrl));
    }

  /**
   * Shows the publishing date of the post and updates this date live.
   * @private
   */
  _setupDate(postId, timestamp, post)
  {
    //const post = this.postElement;

    $('.fp-time', post).attr('href', `/post/${postId}`);
    $('.fp-time', post).text(friendlyPix.Post.getTimeText(timestamp));
    // Update the time counter every minutes.
    this.timers.push(setInterval(
      () => $('.fp-time', post).text(friendlyPix.Post.getTimeText(timestamp)), 60000));
  }

  /**
   * Shows comments and binds actions to the comments form.
   * @private
   */
  _setupComments(postId, author, imageText, post)
  {
    //const post = this.postElement;

    // Creates the initial comment with the post's text.
    //$('.fp-first-comment', post).empty();
    //$('.fp-first-comment', post).append(friendlyPix.Post.createCommentHtml(author, imageText));

    // Load first page of comments and listen to new comments.
    $('.fp-comments', post).empty();
    friendlyPix.firebase.getComments(postId).then(data =>
    {
      this.displayComments(data.entries);
      this.displayNextPageButton(data.nextPage);

      // Display any new comments.
      const commentIds = Object.keys(data.entries);
      friendlyPix.firebase.subscribeToComments(postId, (commentId, commentData) => {
        $('.fp-comments', post).append(
          friendlyPix.Post.createCommentHtml(commentData.author, commentData.text));
      }, commentIds ? commentIds[commentIds.length - 1] : 0);
    });

    if (this.auth.currentUser) {
      // Bind comments form posting.
      $('.fp-add-comment', post).off('submit');
      $('.fp-add-comment', post).submit(e => {
        e.preventDefault();
        const commentText = $(`.mdl-textfield__input`, post).val();
        if (!commentText || commentText.length === 0) {
          return;
        }
        friendlyPix.firebase.addComment(postId, commentText);
        $(`.mdl-textfield__input`, post).val('');
      });
      const ran = Math.floor(Math.random() * 10000000);
      $('.mdl-textfield__input', post).attr('id', `${postId}-${ran}-comment`);
      $('.mdl-textfield__label', post).attr('for', `${postId}-${ran}-comment`);
      // Show comments form.
      $('.fp-action', post).css('display', 'flex');
    }
  }

  /**
   * Shows/Hode and binds actions to the Delete button.
   * @private
   */
  _setupDeleteButton(postId, author, picStorageUri, thumbStorageUri, post)
  {
    //const post = this.postElement;

    if (this.auth.currentUser && this.auth.currentUser.uid === author.uid && picStorageUri) {
      $('.fp-delete-post', post).show();
      $('.fp-delete-post', post).off('click');
      $('.fp-delete-post', post).click(() => {
        swal({
          title: 'Are you sure?',
          text: 'You will not be able to recover this post!',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Yes, delete it!',
          closeOnConfirm: false,
          showLoaderOnConfirm: true,
          allowEscapeKey: true
        }, () => {
          $('.fp-delete-post', post).prop('disabled', true);
          friendlyPix.firebase.deletePost(postId, picStorageUri, thumbStorageUri).then(() => {
            swal({
              title: 'Deleted!',
              text: 'Your post has been deleted.',
              type: 'success',
              timer: 2000
            });
            $('.fp-delete-post', post).prop('disabled', false);
            page(`/user/${this.auth.currentUser.uid}`);
          }).catch(error => {
            swal.close();
            $('.fp-delete-post', post).prop('disabled', false);
            const data = {
              message: `There was an error deleting your post: ${error}`,
              timeout: 5000
            };
            this.toast[0].MaterialSnackbar.showSnackbar(data);
          });
        });
      });
    } else {
      $('.fp-delete-post', post).hide();
    }
  }

  /**
   * Starts Likes count listener and on/off like status.
   * @private
   */
  _setupLikeCountAndStatus(postId, post)
  {
    //const post = this.postElement;

    if (this.auth.currentUser)
    {
      // Listen to like status.
      friendlyPix.firebase.registerToUserLike(postId, isliked =>
       {
        if (isliked)
        {
          $('.fp-liked', post).show();
          $('.fp-not-liked', post).hide();
        } else {
          $('.fp-liked', post).hide();
          $('.fp-not-liked', post).show();
        }
      });

      // Add event listeners.
      $('.fp-liked', post).off('click');
      $('.fp-liked', post).click(() => friendlyPix.firebase.updateLike(postId, false));
      $('.fp-not-liked', post).off('click');
      $('.fp-not-liked', post).click(() => friendlyPix.firebase.updateLike(postId, true));
    } else {
      $('.fp-liked', post).hide();
      $('.fp-not-liked', post).hide();
      $('.fp-action', post).hide();
    }

    // Listen to number of Likes.
    friendlyPix.firebase.registerForLikesCount(postId, nbLikes =>
    {
      if (nbLikes > 0) {
        $('.fp-likes', post).show();
        $('.fp-likes', post).text(nbLikes + ' like' + (nbLikes === 1 ? '' : 's'));
      } else {
        $('.fp-likes', post).hide();
      }
    });
  }

    /**
     * Starts MyFreebie Count Listener and adds post to MyFreebie Feed///////////////////////////////////////////////////////
     * @private
     */
    _setupMyFreebieStatus(postId, post)
    {
      //const post = this.postElement;

      if (this.auth.currentUser)
      {
        // Listen to like status.
        friendlyPix.firebase.registerToUserFreebie(postId, isliked =>
         {
          if (isliked)
          {
            $('.fp-remove-myfreebie', post).show();
            //$('.fp-myfreebie-button', post).removeClass('mdl-color--amber-400');
            //$('.fp-myfreebie-button', post).addClass('mdl-color--grey-400');
            $('.fp-add-myfreebie', post).hide();
          } else {
            $('.fp-remove-myfreebie', post).hide();
            //$('.fp-myfreebie-button', post).removeClass('mdl-color--grey-400');
            //$('.fp-myfreebie-button', post).addClass('mdl-color--amber-400');
            $('.fp-add-myfreebie', post).show();
          }
        });

        // Add event listeners.
        $('.fp-add-myfreebie', post).off('click');
        $('.fp-add-myfreebie', post).click(() => friendlyPix.firebase.updateMyFreebies(postId, true));
        $('.fp-remove-myfreebie', post).off('click');
        $('.fp-remove-myfreebie', post).click(() => friendlyPix.firebase.updateMyFreebies(postId, false));

      } else {
        $('.fp-myfreebie-button',post).hide();
        $('.fp-button',post).hide();
      }
    }


    /**
     * Returns the HTML for a Freebie Feed Post without comments////////////////////////////////////////////////////////////
     */
    static createFreebieFeedHtml()
    {
      return `
          <div class="fp-freebiefeed mdl-cell mdl-cell--12-col mdl-cell--8-col-tablet
                      mdl-cell--8-col-desktop mdl-grid mdl-grid--no-spacing">
            <a href="/post/" class="fp-title-link">
              <div class="mdl-card mdl-shadow--16dp mdl-cell mdl-cell--12-col mdl-cell--12-col-tablet mdl-cell--12-col-desktop">
                <div class="mdl-card__title mdl-color--light-blue-600 mdl-color-text--white">
                    <h1 class="mdl-card__title-text"></h1>
                </div>
              <div class="fp-image"></div>
              <div class="fp-header">
                <a class="fp-usernamelink mdl-button mdl-js-button" href="/user/">
                  <div class="fp-avatar"></div>
                  <div class="fp-username mdl-color-text--black"></div>
                </a>
                <a href="/post/" class="fp-time">now</a>
                <br/>
                <div class="fp-description">
                    <span class="fp-freebie_body_text mdl-card_subtitle-text"></span>
                    <a href="/post/" class="fp-title-link mdl-button mdl-js-button mdl-js-ripple-effect mdl-button--accent"> Weiterlesen...</a
                </div>
                <div class="fp-likes">0 likes</div>
                <div class="fp-action">
                  <span class="fp-like">
                        <div class="fp-not-liked material-icons">favorite_border</div>
                        <div class="fp-liked material-icons">favorite</div>
                  </span>
                </div>
                <div class="fp-button">
                    <div class="fp-add-myfreebie mdl-button mdl-js-button mdl-button--raised mdl-color--amber-400 mdl-shadow--4dp mdl-js-ripple-effect"><i class="material-icons">add_shopping_cart</i> - Freebie vormerken</div>
                    <div class="fp-remove-myfreebie mdl-button mdl-js-button mdl-button--raised mdl-color--grey-400 mdl-shadow--4dp mdl-js-ripple-effect"><i class="material-icons">shopping_basket</i>  - Freebie entfernen</div>
                </div>
              </div>
            </a>
          </div>`;
    }


    _setupCampaignUrl(campaignUrl, post)
    {
      $('.fp-campaign-url', post).attr('href', `${campaignUrl}`);
      $('.fp-campaign-url', post).text(campaignUrl);
    }

    _setupForumUrl(forumUrl, post)
    {
          $('.fp-forum-url', post).attr('href', `${forumUrl}`);
          $('.fp-forum-url', post).text(forumUrl);
    }
    _setupFreebieDates(startDate, endDate, post)
    {
      $('.fp-startDate', post).text(startDate);
      $('.fp-endDate', post).text(endDate);
    }
    _setupSaving(saving, post)
    {
      $('.fp-saving', post).text(saving);
    }
    // Set Organizer <div class="fp-organizer"></div>
    //_setupOrganizer(organizer, post)
    //{
      //$('.fp-organizer', post).text(organizer);
    //}
    _setupFreebieCharacteristics(online, offline, feedback, daysToClaim, post)
    {
      if(online === 'on')
      {
        if(offline === 'on')
        {
          $('.fp-onlineClaim', post).text('Den Kassenbon kannst du online aber auch per Post einreichen. ');
        }else{
          $('.fp-onlineClaim', post).text('Den Kassenbon kannst du online einreichen. ');
        }
      }

      if(feedback === null || feedback === 'off')
      {
        //$('.fp-feedback', post).text("Bei dieser Aktion musst du kein Feedback abgeben.");
      }else { //Bei dieser Aktion bewertest du den Artikel oder begründest, warum er dir ggf. nicht gefallen hat.Bei dieser Aktion gibst du eine kurze Produktbewertung ab oder begründest, warum dir dieser ggf. nicht gefallen hat.
        $('.fp-feedback', post).text("Bei dieser Aktion bewertest du den Artikel oder begründest, warum er dir ggf. nicht gefallen hat.");
      }

      if(daysToClaim !== null && daysToClaim !== "")
      {
        $('.fp-daysToClaim', post).text('Du musst aber spätestens '+daysToClaim +' Tage nach dem Kauf dein Kassenbon einreichen.');
      }
    }

    /**
    * Returns the HTML for a post with comments.
    <div class="mdl-card__menu">
                       <button id="fp-share-freebie" class="mdl-button mdl-button--icon mdl-button--raised mdl-color--amber-grey-400 mdl-shadow--4dp">
                           <i class="material-icons">share</i>
                       </button>
                       <ul class="mdl-menu mdl-menu--bottom-right mdl-js-menu" for="fp-share-freebie">
                          <li class="mdl-menu__item">Facebook</li>
                          <li class="mdl-menu__item">Twitter</li>
                          <li class="mdl-menu__item">Pinterest</li>
                       </ul>
                  </div>
    */
    static createPostHtml()
    {
      return `
        <div class="fp-post mdl-cell mdl-cell--12-col mdl-cell--8-col-tablet mdl-cell--8-col-desktop mdl-grid mdl-grid--no-spacing">
          <div class="mdl-card mdl-shadow--16dp mdl-cell mdl-cell--12-col mdl-cell--12-col-tablet mdl-cell--12-col-desktop">
              <div class="mdl-card__title mdl-color--light-blue-600 mdl-color-text--white">
                        <h1 class="mdl-card__title-text"></h1>
              </div>
              <div class="fp-image"></div>

              <div class="fp-header">
                          <a class="fp-usernamelink mdl-button mdl-js-button" href="/user/">
                            <div class="fp-avatar"></div>
                            <div class="fp-username mdl-color-text--black"></div>
                          </a>
                          <!-- Delete button -->
                          <button class="fp-delete-post mdl-button mdl-js-button">
                            Delete
                          </button>
                          <a href="/post/" class="fp-time">now</a>
                <br/>
                <div class="fp-description">
                  <span class="fp-freebie_body_text"></span>
                </div>

                <div class="mdl-card__actions mdl-card--border">
                  <div class="mdl-layout-spacer"></div>
                </div>

                <div class="fp-likes">0 likes</div>
                <div class="fp-description">
                  Die Freebie Aktion hat am <span class="fp-startDate"></span> gestartet und endet vorrausichtlich am
                  <span class="fp-endDate"></span>. <span class="fp-onlineClaim"></span>
                  Insgesamt sparst du bei diesem Geld-zurück-Produkt bis zu <span class="fp-saving"></span> Euro.

                  <div class="fp-feedback"></div>
                  <span class="fp-daysToClaim"></span><br/>

                  Link zur Freebie Kampagne: <a href="" class="fp-campaign-url" target="_blank"></a><br/>
                  Link zur MyDealz Diskussion: <a href="" class="fp-forum-url" target="_blank"></a>
                </div>

                <div class="fp-morecomments">View more comments...</div>
                <div class="fp-comments"></div>
                <div class="fp-action">
                  <span class="fp-like">
                    <div class="fp-not-liked material-icons">favorite_border</div>
                    <div class="fp-liked material-icons">favorite</div>
                  </span>
                  <form class="fp-add-comment" action="#">
                    <div class="mdl-textfield mdl-js-textfield">
                      <input class="mdl-textfield__input" type="text">
                      <label class="mdl-textfield__label">Comment...</label>
                    </div>
                  </form>
                </div>
              </div>
          </div>
        </div>`;
  }

  /**
   * Returns the HTML for a post's comment.
   */
  static createCommentHtml(author, text) {
    return `
        <div class="fp-comment">
            <a class="fp-author" href="/user/${author.uid}">${$('<div>').text(author.full_name || 'Anonymous').html()}</a>:
            <span class="fp-text">${$('<div>').text(text).html()}</span>
        </div>`;
  }

  /**
   * Given the time of creation of a post returns how long since the creation of the post in text
   * format. e.g. 5d, 10h, now...
   */
  static getTimeText(postCreationTimestamp)
  {
    let millis = Date.now() - postCreationTimestamp;
    const ms = millis % 1000;
    millis = (millis - ms) / 1000;
    const secs = millis % 60;
    millis = (millis - secs) / 60;
    const mins = millis % 60;
    millis = (millis - mins) / 60;
    const hrs = millis % 24;
    const days = (millis - hrs) / 24;
    var timeSinceCreation = [days, hrs, mins, secs, ms];

    let timeText = 'Now';
    if (timeSinceCreation[0] !== 0) {
      timeText = timeSinceCreation[0] + 'd';
    } else if (timeSinceCreation[1] !== 0) {
      timeText = timeSinceCreation[1] + 'h';
    } else if (timeSinceCreation[2] !== 0) {
      timeText = timeSinceCreation[2] + 'm';
    }
    return timeText;
  }
};

friendlyPix.post = new friendlyPix.Post();

$(document).ready(() => {
  // We add the Post element to the single post page.
  $('.fp-image-container', friendlyPix.post.postPage).append(friendlyPix.post.postElement);
});
